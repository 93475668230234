<template>
    <div class="page">
        <div class="content">
            <span class="text">欢迎使用{{name}}系统</span>
            <img class="bg-img" src="../../assets/home/bg.png" alt="" draggable="false">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {                
                name: '兼职管理',
            }
        },
    }

</script>

<style lang="scss" scoped>
.page{
    width: 100%;
    height: 100%;
    background-color: #E9F0F2;
    padding: 20px;
}
.content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 40px;
    position: relative;
}
.bg-img {
    width: 70vw;    
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
.text{
    width: 464px;
    height: 60px;
    font-size: 45px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3995FE;
    line-height: 136px;
    position: absolute;
    left: 100px;
    top: 50px;
    z-index: 9999;
    user-select: none;
}
</style>